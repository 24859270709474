<template>
    <div>
        <!-- 顶部工具条 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='状态:'>
                        <el-select v-model="filters.status" placeholder="请选择">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='是否达标:'>
                        <el-select v-model="filters.isStandard" placeholder="请选择">
                            <el-option
                            v-for="item in options1"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='允许考试:'>
                        <el-select v-model="filters.allowExam" placeholder="请选择">
                            <el-option
                            v-for="item in options2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='行业:'>
                        <el-select v-model="filters.tradeID" placeholder="请选择">
                            <el-option
                            v-for="item in options3"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='岗位:'>
                        <el-select v-model="filters.stationID" placeholder="请选择">
                            <el-option
                            v-for="item in options4"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <div style='display:flex'>
                            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                            <el-button @click="exportTemplate" type="warning">导出模板</el-button>
                        </div>             
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
            border
            highlight-current-row
            :data="tableData"
            @current-change='currentChange'
            :header-cell-style="headClass"
            :cell-style='rowClass'
            style="width: 95%"
            v-loading='listLoading'>
            <el-table-column type="index" label='序号' width="60"></el-table-column>
            <el-table-column prop='UnitName' label='社会单位名称' width='170'></el-table-column>
            <el-table-column prop='SocialCreditNumber' label='社会统一信用代码证' width='200'></el-table-column>
            <el-table-column prop='Name' label='姓名' width='110'></el-table-column>
            <el-table-column prop='CardNo' label='身份证号' width='180'></el-table-column>
            <el-table-column prop='PhoneNumber' label='手机号' width='120'></el-table-column>
            <el-table-column prop='TradeName' label='行业名称' min-width="100"></el-table-column>
            <el-table-column prop='StationName' label='岗位名称' min-width="100"></el-table-column>
            <el-table-column prop='QualifiedLineDuration' label='合格学习时长(时)' width='140'></el-table-column>
            <el-table-column prop='OnlineDuration' label='线上学习时长(时)' width='140'></el-table-column>
            <el-table-column prop='OffLineDuration' label='线下面授时长(时)' width='140'></el-table-column>
            <el-table-column prop='HandsOnDuration' label='实操时长(时)' width='140'></el-table-column>
            <el-table-column prop='Remarks' label='备注'></el-table-column>
            <el-table-column prop='IsStandard' label='是否达标' fixed='right' width='90'>
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.IsStandard == true' type='success'>是</el-tag>
                    <el-tag v-else type='warning'>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='AllowExam' label='允许考试' fixed='right' width='90'>
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.IsStandard == true' type='success'>是</el-tag>
                    <el-tag v-else type='danger'>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='Status' label='状态' fixed='right' width='90'>
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.Status == 0' type='danger'>无效</el-tag>
                    <el-tag v-if='scope.row.Status == 1' type='success'>已取证</el-tag>
                    <el-tag v-if='scope.row.Status == 2' type='primary'>学习中</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
         <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.pageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.dataCount">
        </el-pagination>
        <!-- 导入对话框 -->
        <el-dialog
        title="导入"
        :visible.sync="importDialogVisible"
        :close-on-click-modal="false"
        width="500px">
        <el-upload
        drag
        :limit='1'
        ref="upload"
        :on-error="submitFileError"
        :on-success="submitFileSuccess"
        :before-upload="beforeUpload"
        :on-exceed="submitFileMore"
        :show-file-list="false"
        :action="actions"
        :headers='headers'
        :multiple='false'>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传文件，且不超过2MB</div>
        </el-upload>
        <span slot="footer" class="dialog-footer">
            <el-button @click="importDialogVisible = false">取 消</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from "qs";
import { getStudentLearnRecordListPage,getDataDictionaryList,importStudentLearnRecord,getImportStudentLearnRecordTemplateUrl } from '../../api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList:[],
            filters:{
                status:'',
                isStandard:'',
                allowExam:'',
                stationID:'',
                tradeID:''
            },
            options:[
                {label:'无效',value:0},
                {label:'已取证',value:1},
                {label:'学习中',value:2}
            ],
            options1:[
                {label:'是',value:true},
                {label:'否',value:false},
            ],
            options2:[
                {label:'允许',value:true},
                {label:'禁止',value:false},
            ],
            options3:[],
            options4:[],
            currentRow:null,
            tableData:[],
            pages:{
                dataCount:0,
                pageIndex:1,
                pageSize:20
            },
            listLoading:false,
            importDialogVisible:false, // 导入对话框隐藏与显示
            actions:'',
        }
    },
    computed:{
        headers() {
            return{
                "Authorization": 'Bearer ' + localStorage.getItem("Token") // 直接从本地获取token就行
            }
        },
    },
    methods:{
        // 列表文字居中
        headClass(){
            return 'text-align:center;'
        },
        rowClass(){
            return 'text-align:center;'
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 获取列表当前行数据
        currentChange(value){
            this.currentRow = value
        },
        // 分页处理
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.pageIndex = value
            this.getData()
        },
        // 查询
        getStudentLearnRecordListPage(){
            this.getData()
        },
        // 导入
        importStudentLearnRecord(){           
            this.importDialogVisible = true
            this.actions = importStudentLearnRecord
        },
        // 文件上传失败
        submitFileError(err,file,fileList){
            this.$message.error('文件上传失败!')
            this.$refs.upload.clearFiles() 
        },
        // 文件上传成功
        submitFileSuccess(res,file,fileList) {
            if(res.Success){
                console.log('1111')
                this.$message({
                    message:'文件上传成功！',
                    type:'success'
                })
                this.closeDialog()
                this.getData();
                this.$refs.upload.clearFiles() 
            }else{
                console.log('222')
                this.$message({
                    message:res.Message,
                    type:'error'
                })
                this.$refs.upload.clearFiles() 
            }
        },
        // 文件上传前限制
        beforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 > 2;
            var type = file.name.substring(file.name.lastIndexOf(".")+1);
            if (type == 'jpg' || type == 'png') {
                this.$message.error('只能上传文件!');
                return false;
            }
            if (!isLt2M === false) {
                this.$message.error('上传文件大小不能超过 2MB!');
                return false;
            }
        },
        submitFileMore(files) {
            this.$message({
                message:'只能上传一个文件！',
                type:'warning'
            })
        },
        // 导出模板
        exportTemplate(){
            getImportStudentLearnRecordTemplateUrl().then(res => {
                if(res.status == 200){
                    window.open(res.data)
                }else{
                    this.$message.error('模板导出失败!')
                }
            })
        },
        // 关闭导入对话框
        closeDialog(){
            setTimeout(() => {
                this.importDialogVisible = false;
            },3000)           
        },
        // 获取列表数据
        getData(){
            this.listLoading = true
            var params = {
                status:this.filters.status ? this.filters.status : '',
                isStandard:this.filters.isStandard ? this.filters.isStandard : '',
                allowExam:this.filters.allowExam ? this.filters.allowExam : '',
                stationId:this.filters.stationID ? this.filters.stationID : '',
                tradeId:this.filters.tradeID ? this.filters.tradeID : '',
                pageIndex:this.pages.pageIndex,
                pageSize:this.pages.pageSize
            }
						params.projectId = window.localStorage.getItem('projectId')
            getStudentLearnRecordListPage(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {})
        },
        // 获取行业/岗位信息
        getInfo(params){
            var params = {
                dataType:0
            }        
            var params1 = {
                dataType:1
            }  
            getDataDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.options3 = res.data.Response
                }else{return}
            }).catch(() => {})           
            getDataDictionaryList(params1).then(res => {
                if(res.data.Success){
                    this.options4 = res.data.Response
                }else{return}
            }).catch(() => {})
        },
    },
    mounted(){
        this.getData()
        this.getInfo()
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style>
    .el-upload-dragger{
        margin:auto!important;
    }
    .el-upload{
        display:block!important;
    }
    .el-upload__tip{
        margin-left:60px;
    }
</style>